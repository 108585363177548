<template>
  <!-- 跳转的按钮 -->
  <!-- c-award__container--active -->
  <!-- ICON -->
  <!-- award-type-0  0 1 23-->

  <div class="home-marketwheel" v-if="html">
    <div class="roulette-container l-scene__roulette">
      <template v-for="(item, index) in prize_arr">
        <!-- 积分 -->
        <div
          v-if="item.type == 1"
          class="c-award__container"
          :class="{ 'c-award__container--active': active == index + 1 }"
        >
          <i class="c-award__icon award-type-1" />
          <p class="l-general__desc c-award__desc">{{ item.point }}积分</p>
        </div>
        <!-- 优惠券 -->
        <div
          v-if="item.type == 2"
          class="c-award__container"
          :class="{ 'c-award__container--active': active == index + 1 }"
        >
          <i class="c-award__icon award-type-2" />
          <p class="l-general__desc c-award__desc">
            {{ item.vouchertemplate_price }}优惠券
          </p>
        </div>
        <!-- 产品 -->
        <div
          v-if="item.type == 3"
          class="c-award__container"
          :class="{ 'c-award__container--active': active == index + 1 }"
        >
          <!-- <i class="c-award__icon award-type-0 "/> -->
          <img
            :src="item.img"
            style="width: 1.6rem; margin: 0 auto; display: block"
          />
          <p class="l-general__desc c-award__desc">{{ item.name }}</p>
        </div>
        <!-- 谢谢 -->
        <div
          v-if="item.type == 0"
          class="c-award__container"
          :class="{ 'c-award__container--active': active == index + 1 }"
        >
          <i class="c-award__icon award-type-0" />
          <p class="l-general__desc c-award__desc">谢谢参与</p>
        </div>
      </template>
    </div>
    <button
      class="l-lottery__btn"
      @click="goIng"
      style="top: 80%"
      v-if="num_config > 0"
    >
      <div class="c-btn__action">立即抽奖</div>
      <!-- <div class="c-btn__credit"></div> -->
      <!-- <div class="c-btn__chance" v-if="countLeft">
                剩余{{countLeft}}次机会
            </div> -->
    </button>

    <button
      class="l-lottery__btn l-lottery__btn--disable"
      v-else
      style="top: 80%"
    >
      <div class="c-btn__action">下次再来吧~</div>
      <div class="c-btn__credit"></div>
      <div class="c-btn__chance">次数用完了</div>
    </button>

    <div class="l-scene__user">
      <!-- {{ $moment.unix(item.create_time).format('YYYY.MM.DD') }} -->
      <!-- {{jihui}}{{num_config}}{{user_lottery_count.sum_num}} -->

      <p class="c-user__credit">抽奖次数：{{ num_config }}</p>
      <!-- <p class="c-user__credit" v-else >抽奖次数：{{num_config - user_lottery_count.today_use_num}}</p> -->
      <a class="c-user__record" @click="goLog"> 我的中奖记录 </a>
    </div>

    <div class="l-scene__user">
      <!-- {{ $moment.unix(item.create_time).format('YYYY.MM.DD') }} -->
      <p class="c-user__credit"></p>
      <!-- <a class="c-user__record" @click="goLog"> 我的中奖记录 </a> -->
    </div>

    <vanPopup class="popup" v-model="show">
      <div class="c-result__popup">
        <div class="c-result__container">
          <div class="c-result__title">
            {{ prize_info.title }}
          </div>
          <div class="l-result__prize">
            <i v-if="prize_info.type == 0" class="c-prize__icon award-type-0" />
            <i v-if="prize_info.type == 1" class="c-prize__icon award-type-1" />
            <i v-if="prize_info.type == 2" class="c-prize__icon award-type-2" />
            <img
              v-if="prize_info.type == 3"
              :src="prize_info.img"
              style="width: 5rem; margin: 0 auto; display: block"
            />
            <p class="c-prize__name"></p>
            <p class="c-prize__expire">{{ prize_info.name }}</p>
          </div>
          <div class="button-wrapper">
            <vanButton type="danger" size="large" @click="show = false">
              知道了
            </vanButton>
          </div>
        </div>
      </div>
    </vanPopup>
  </div>

  <div v-else>
    <div class="home-marketwheel" v-if="error_code">
      <p
        style="
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          width: 100%;
          height: 30px;
          text-align: center;
          color: #fff;
          font-size: 28px;
        "
      >
        {{ tips }}
      </p>
    </div>
    <div class="home-marketwheel" v-else>
      <vanPopup
        class="popup"
        v-model="isResult"
        lock-scroll
        close-on-popstate
        :close-on-click-overlay="false"
      >
        <div class="c-result__popup">
          <div class="c-result__container">
            <div class="c-result__title">提示</div>
            <div class="l-result__prize">
              <i class="c-prize__icon award-type-0" />
              <p class="c-prize__expire">登录后才可以抽奖哦~</p>
            </div>
            <div class="button-wrapper">
              <vanButton type="danger" size="large" @click="setLogin">
                知道了
              </vanButton>
            </div>
          </div>
        </div>
      </vanPopup>
    </div>
  </div>
</template>

<script>
import { Toast, Popup, Button } from "vant";
import { mapState } from "vuex";
import { post } from "@get/http";
export default {
  name: "Marketwheel",
  components: {
    vanPopup: Popup,
    vanButton: Button,
  },
  data() {
    return {
      tips: "活动未开始",
      prize_arr: [],
      p_num: 12, //多少个中奖
      active: false, //运动
      count: 48, //圈数  count/p_num = 3
      user_lottery_count: {}, //使用次数
      join_num: 0, //抽奖模式
      chouNum: 0, //当前抽了多少次
      isResult: true,
      jihui: true, //是否还有机会
      html: true, //活动正确
      error_code: true, //错误代码
      show: false, //展示中奖弹窗
      num_config: 0,
      prize_info: {},
      mode: 1, //抽奖模式  0为正常显示奖品  1为随机展示产品
    };
  },
  computed: {
    ...mapState({
      isOnline: (state) => state.member.isOnline,
      token: (state) => state.member.token,
      info: (state) => state.member.info,
    }),
  },
  async created() {
    //获取中奖信息
    try {
      let res = await post(
        "/MemberLottery/show",
        {
          data: {
            id: this.$route.query.id,
          },
        },
        {
          result: true,
          resultKey: "code",
          setupLogin: false,
        }
      );

      if (res.code == 10001) {
        // 活动不存在
        this.tips = res.message;
        if (this.tips == "抽奖活动不存在") {
          this.tips = "活动未开始";
        }
        this.html = false;
        return false;
      }
      if (res.code == 11001) {
        // 没有登录
        this.html = false;
        this.error_code = false;
        return false;
      }

      let data = res.result.prize_config,
        arr = [],
        PR = 0;

      for (var i = 0; i < this.p_num; i++) {
        if (data && data[i]) {
          PR += data[i].prize_radio;
          arr[i] = {
            type: data[i]?.prize_type, //中奖类型
            prize_level: data[i]?.prize_level, //几等奖
            point: data[i]?.prize_config.point, //积分
            vouchertemplate_price: data[i]?.prize_config?.vouchertemplate_price, //优惠券价格
            voucher_id: data[i]?.prize_config?.voucher_id, //优惠券ID
            img: data[i]?.prize_config?.goods_image, //产品图片
            name: data[i]?.prize_config?.goods_name, //产品名字
          };
        }
      }

      if (this.mode == 0) {
        let pos = this.p_num - arr.length; //坑位
        for (var i = 0; i < pos; i++) {
          arr.push({ type: 0, prize_level: 0 });
        }
      } else {
        if (PR >= 100) {
          this.SetArr(arr, 1);
        } else {
          this.SetArr(arr, 1);
        }
      }

      if (res.result.join_num == 3) {
        this.num_config = 99999999999;
      } else {
        this.num_config = res.result.user_lottery_count.not_use_num;
      }

      this.prize_arr = this.randArr(arr); //打乱数组
      this.join_num = res.result.join_num;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    SetArr(arr, mode = 0) {
      if (mode == 1) {
        arr.push({ type: 0, prize_level: 0 });
      }
      let pos = this.p_num - arr.length; //坑位
      for (var i = 0; i < pos; i++) {
        let iRands = parseInt(arr.length * Math.random());
        arr.push(arr[iRands]);
      }
    },

    setLogin() {
      this.$store.dispatch("doSetupLogin", true);
    },
    randArr(arr) {
      //打乱数组
      for (var i = 0; i < arr.length; i++) {
        var iRand = parseInt(arr.length * Math.random());

        var temp = arr[i];
        arr[i] = arr[iRand];
        arr[iRand] = temp;
      }
      return arr;
    },
    returnPos(level) {
      let arr = [];
      //查找抽中的位置
      for (var i = 0; i < this.prize_arr.length; i++) {
        if (this.prize_arr[i].prize_level == level) {
          arr.push(i);
        }
      }
      if (arr.length > 1) {
        let iRand = parseInt(arr.length * Math.random());
        return arr[iRand];
      } else if (arr.length == 1) {
        //为了避免随机数出错 做多一层判断
        return arr[0];
      } else {
        return this.returnPos(0);
      }
    },
    async goIng() {
      if (this.isResult == false) {
        alert("正在抽奖");
        return false;
      }
      this.isResult = false; //开锁
      let res = await post(
        "/MemberLottery/add_log",
        {
          data: {
            id: this.$route.query.id,
          },
        },
        {
          result: true,
          resultKey: "code",
          toast: false,
        }
      );
      if (res.code == 10001) {
        alert(res.message);
        this.jihui = false;
        this.num_config = 0;
        return false;
      }

      if (res.result.prize_type == 2) {
        this.prize_info = {
          type: 2,
          title: "恭喜您中奖了",
          name:
            res.result.prize_config.vouchertemplate_price +
            "元优惠券，已发到账户",
        };
      } else if (res.result.prize_type == 1) {
        this.prize_info = {
          type: 1,
          title: "恭喜您中奖了",
          name: res.result.prize_config.point + "积分，已发到账户",
        };
      } else if (res.result.prize_type == 3) {
        this.prize_info = {
          type: 3,
          title: "恭喜您中奖了",
          name: res.result.prize_config.goods_name,
          img: res.result.prize_config.goods_image,
        };
      }

      if (res.code == 10002) {
        this.prize_info = {
          type: 0,
          title: "没有中奖哦~",
          name: "擦肩而过",
        };
      }

      let pos = this.returnPos(res.result.prize_level); //找到中奖位置
      this.active = false; //每次开始都需要重置
      this.count = 48 + (pos + 1); //中奖位置赋值
      this.num_config--;
      // if(this.num_config <= 0){}
      this.setActive(); //运动

      // this.setActive();
    },
    goLog() {
      this.$router.push({ name: "luckRecord" });
    },
    setActive(speed = 400) {
      // 抽奖运动

      if (this.count > 0) {
        if (!this.active) {
          this.active = 1;
        } else {
          this.active = (this.active % this.p_num) + 1;
        }
        this.count = this.count - 1;
        let _this = this;
        if (speed > 50) {
          speed = speed - 50;
        }
        if (this.count < 8) {
          speed = 400 - 50 * this.count;
        }
        setTimeout(function() {
          _this.setActive(speed);
        }, speed);
      } else {
        this.isResult = true;
        this.show = true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.home-marketwheel {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fe302f;
  overflow-y: auto;
  background-image: url(~@image/luck//home-marketwheel-bg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
}
.roulette-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: relative;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 10px 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
  margin: 185px 15px 10px 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.c-award__container {
  background: #fff;
  border-radius: 4px;
  padding: 10px 0;
  margin-bottom: 10px;
  width: -webkit-calc(25% - 5.5px);
  width: -moz-calc(25% - 5.5px);
  width: calc(25% - 5.5px);
  box-shadow: inset 0 0 24px 0 #ffd6ab, 0 0.25em 0 rgba(248, 166, 131, 0.96);
}

.c-award__container--hidden {
  visibility: hidden;
}

.c-award__container--active {
  border-radius: 4px;
  background: #ffceab;
  box-shadow: inset 0 0 8px 0 #ff9358, 0 0.25em 0 rgba(248, 166, 131, 0.96);
}
.c-award__icon {
  width: 32px;
  height: 32px;
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.c-award__icon.award-type-0 {
  background-image: url(~@image/luck//home-marketwheel-0.png);
}
.c-award__icon.award-type-1 {
  background-image: url(~@image/luck//home-marketwheel-1.png);
}
.c-award__icon.award-type-2 {
  background-image: url(~@image/luck//home-marketwheel-2.png);
}
.c-award__icon.award-type-3 {
  background-image: url(~@image/luck//home-marketwheel-3.png);
}
.c-award__desc {
  opacity: 0.8;
  color: #333;
  margin-top: 5px;
  font-size: 10px;
  line-height: 17px;
  text-align: center;
}
.l-lottery__btn {
  top: 50%;
  left: 50%;
  border: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  width: 140px;
  height: 140px;
  color: #fff;
  position: absolute;
  border-radius: 50%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #f44;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2),
    inset 0 -0.25em 0 rgba(0, 0, 0, 0.25), 0 0.25em 0.25em rgba(0, 0, 0, 0.05);
  text-decoration: none;
  text-shadow: 0 1px 1px hsla(0, 0%, 100%, 0.25);
  vertical-align: middle;
}

.l-lottery__btn:active {
  box-shadow: inset 12px 12em 0 hsla(0, 0%, 100%, 0.1),
    inset 0 0.25em 0.5em rgba(0, 0, 0, 0.05);
  margin-top: 0.2em;
  outline: none;
  padding-bottom: 0.3em;
}

.l-lottery__btn--disable {
  box-shadow: 0 0.25em 0 rgba(174, 165, 161, 0.96);
  background: #d0d0d0;
}

.l-lottery__btn--disable:active {
  margin-top: 0;
  padding-bottom: 0;
}

.c-btn__action {
  font-size: 24px;
  margin-top: 10px;
  line-height: 33px;
  letter-spacing: 0.43px;
}
.c-btn__chance {
  font-size: 12px;
  line-height: 17px;

  padding: 0 8px;
  margin-top: 8px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
}
.l-scene__user {
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin: 10px 15px 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
}

.l-scene__user .c-user__record {
  color: #fff;
  text-decoration: underline;
}
.c-result__popup {
  width: 305px;
  background: #fff;
  border-radius: 8px;
  overflow: visible;
  background-image: url(~@image/luck//home-marketwheel-popup.png);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top center;
  z-index: 2001;
}
.c-result__container {
  position: relative;
}

.c-result__title {
  color: #fff;
  font-size: 38px;
  font-weight: 500;
  padding-top: 32px;
  text-align: center;
  letter-spacing: 0.63px;
}

.l-result__prize {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  margin: 19px 21px;
  border-radius: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  padding: 30px 0 23px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(199, 73, 67, 0.28);
}

.c-prize__icon {
  width: 50px;
  height: 50px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
.c-prize__icon.award-type-0 {
  background-image: url(~@image/luck//home-marketwheel-0.png);
}
.c-prize__icon.award-type-1 {
  background-image: url(~@image/luck//home-marketwheel-1.png);
}
.c-prize__icon.award-type-2 {
  background-image: url(~@image/luck//home-marketwheel-2.png);
}
.c-prize__icon.award-type-3 {
  background-image: url(~@image/luck//home-marketwheel-3.png);
}
.c-prize__name {
  color: #333;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 500;
  line-height: 20px;
}
.popup {
  border-radius: 1rem;
  .button-wrapper {
    padding: 1rem;
    padding-top: 0;
  }
}
.l-general__desc {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  text-align: center;
  margin: 0 auto;
}
.c-award__container {
  padding: 20px 0;
}
</style>
